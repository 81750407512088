<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="transfers"
                          columnsPrefix="transfer.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="transfer.title.transfers"
                >
                    <template #cell(number)="row">
                        <a @click.prevent="$root.$children[0].openModal('kva-modal', row.item.offering_id)"
                           href="#">{{ row.item.number }}</a>
                    </template>

                    <template #cell(actions)="row">
                        <b-button
                            @click="$root.$children[0].openModal('transfer-modal', {id:row.item.id},null, {width: '800px'})"
                            size="sm"
                            variant="info"
                            class="mr-2"
                            :title="$t('common.title.edit')"
                        >
                            <font-awesome-icon icon="edit"/>
                        </b-button>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'Transfers',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('Transfers', ['getTransfersTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Transfers/fetchTransfers', 'getTransfersTable');
        },
        rowClass(item, type) {
            if (item) {
                // if (item.status == 'refunded') return 'table-success'
                // if (item.status == 'partial_refund') return 'table-warning'
                // if (item.status == 'withheld') return 'table-danger'
            }
        },
    },
}
</script>